import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    homeParallaxSlider();
    homeTestimonialsTicker();

    $(window).on('resize scroll', function() {
      homeParallaxSlider();
    });
  },
};

function homeParallaxSlider() {
  $('.home-parallax-slider-section .slider-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: true,
  });

  $('.home-parallax-slider-section .slider-wrp .flickity-button').appendTo($('.home-parallax-slider-section .section-inner-wrp .flickity-button-wrp'));

  $('.home-parallax-slider-section .slider-wrp .flickity-page-dots').appendTo($('.home-parallax-slider-section .section-inner-wrp .flickity-dots-wrp'));

  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  if ($('.home-parallax-slider-section').isInViewport()) {
    $('.home-parallax-slider-section').find('.section-inner-wrp').css('display', 'block');
  } else {
    $('.home-parallax-slider-section').find('.section-inner-wrp').css('display', 'none');
  }
}

function homeTestimonialsTicker() {
  if ($('.home-testimonials-section .testimonial-wrp')) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.home-testimonials-section .testimonial-wrp');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}